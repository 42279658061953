import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import 'dayjs/locale/fr';
import { _x, sprintf } from '@wordpress/i18n';
dayjs.locale('fr');

document.addEventListener('findstrLoaded', function () {
  const findstr = window.findstr || {};
  findstr.hooks.addFilter(
    'searchResults',
    'ithqResults',
    function (results, group) {
      if ('public-jobs' === group) {
        const dateTo = dayjs();

        results.hits.map((hit) => {
          const dateFrom = dayjs(hit.public_job_start_date);

          hit.published_interval_label = sprintf(
            /* translators: %s is time since publication ( 1 hour, 2 days, 3 weeks, x months, x years ), translated */
            _x('Online since %s', 'job offer published date interval', 'vtx'),
            dateFrom.from(dateTo, true)
          );

          return hit;
        });
      }

      if ('graduate-companies' === group) {
        results.hits.map((hit) => {
          hit.see_website_label = _x(
            'See website',
            'company website link',
            'vtx'
          );
          return hit;
        });
      }

      if ('formations' === group) {
        //add permalink arg to formation when address is selected
        const formQueryString = window.location.search;
        const formUrlParams = new URLSearchParams(formQueryString);
        const formCityParam = 'findstr[filter][formation_cities][value][]';

        // Récupérer les valeurs des ateliers
        const formationCities = formUrlParams.getAll(formCityParam);

        // Créer un nouveau modèle d'URL avec les valeurs actuelles
        const newFormationQueryString = formationCities
          .map((city) => `${formCityParam}=${encodeURIComponent(city)}`)
          .join('&');
        results.hits.map((hit) => {
          const currentFormationHref = hit.permalink;
          const updatedFormationHref =
            currentFormationHref.split('?')[0] + '?' + newFormationQueryString;
          hit.permalink = updatedFormationHref;
          if ('1' === hit.formation_price_hidden) {
            hit.formation_price_price = '';
          } else if (hit.formation_price_price) {
            if (0 === parseInt(hit.formation_price_price)) {
              hit.formation_price_price = _x('Free', 'price format', 'vtx');
            } else {
              hit.formation_price_price = sprintf(
                /* translators: %s is price's value */
                _x('$%s', 'price format', 'vtx'),
                hit.formation_price_price
              );
            }
          }
          return hit;
        });
      }

      if ('employees' === group) {
        results.hits.map((hit) => {
          hit.employee_see_more = _x(
            'See more',
            'employee see more link',
            'vtx'
          );
          hit.employee_contact_sheet = _x(
            'Contact sheet',
            'employee contact sheet link',
            'vtx'
          );
          let strToDisplay = '';
          if (hit['teaching-sector'] && hit['teaching-sector'].length > 0) {
            strToDisplay = hit['teaching-sector'].join(', ');
          } else if (
            hit['teaching-order'] &&
            hit['teaching-order'].length > 0
          ) {
            strToDisplay = hit['teaching-order'].join(', ');
          } else if (hit.service && hit.service.length > 0) {
            strToDisplay = hit.service.join(', ');
          } else if (
            hit['employee-category'] &&
            hit['employee-category'].length > 0
          ) {
            strToDisplay = hit['employee-category'].join(', ');
          }
          hit.priority_tax = strToDisplay;
          return hit;
        });
      }

      if ('workshops' === group) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const cityParam = 'findstr[filter][workshop_cities][value][]';

        // Récupérer les valeurs des ateliers
        const workshopCities = urlParams.getAll(cityParam);

        // Créer un nouveau modèle d'URL avec les valeurs actuelles
        const newQueryString = workshopCities
          .map((city) => `${cityParam}=${encodeURIComponent(city)}`)
          .join('&');

        results.hits.map((hit) => {
          const currentHref = hit.permalink;
          const updatedHref = currentHref.split('?')[0] + '?' + newQueryString;
          hit.permalink = updatedHref;

          /**
           * Format price
           *
           * Note this filter has to be applied in PHP as well in order to be app at load time.
           *
           * @see inc/plugins/plugins.findstr.php:76
           */
          if ('1' === hit.workshop_price_hasHiddenPrice) {
            hit.workshop_price_price = '';
          } else if (hit.workshop_price_price) {
            if (0 === parseInt(hit.workshop_price_price)) {
              hit.workshop_price_price = _x('Free', 'price format', 'vtx');
            } else {
              hit.workshop_price_price = sprintf(
                /* translators: %s is price's value */
                _x('$%s', 'price format', 'vtx'),
                hit.workshop_price_price
              );
            }
          }
          return hit;
        });
      }

      return results;
    }
  );

  findstr.hooks.addAction('urlUpdated', 'ithqUrlUpdated', function (group) {
    if ('programs' === group) {
      const url = new URL(window.location.href);
      if (url.search && url.searchParams.get('findstr[group]') === group) {
        document.querySelector('.programsLanding').style.display = 'none';
        document.querySelector('.programsSearchResults').style.display =
          'block';
      } else {
        document.querySelector('.programsLanding').style.display = 'block';
        document.querySelector('.programsSearchResults').style.display = 'none';
      }
    } else if ('formations' === group) {
      const url = new URL(window.location.href);
      if (url.search && url.searchParams.get('findstr[group]') === group) {
        document.querySelector('.formationsLanding').style.display = 'none';
        document.querySelector('.formationsSearchResults').style.display =
          'block';
      } else {
        document.querySelector('.formationsLanding').style.display = 'block';
        document.querySelector('.formationsSearchResults').style.display =
          'none';
      }
    } else if ('workshops' === group) {
      const url = new URL(window.location.href);
      if (url.search && url.searchParams.get('findstr[group]') === group) {
        document.querySelector('.workshopsLanding').style.display = 'none';
        document.querySelector('.workshopsSearchResults').style.display =
          'block';
      } else {
        document.querySelector('.workshopsLanding').style.display = 'block';
        document.querySelector('.workshopsSearchResults').style.display =
          'none';
      }
    } else if ('outlooks' === group) {
      const url = new URL(window.location.href);
      if (url.search && url.searchParams.get('findstr[group]') === group) {
        document.querySelector('.outlooksLanding').style.display = 'none';
        document.querySelector('.outlooksSearchResults').style.display =
          'block';
      } else {
        document.querySelector('.outlooksLanding').style.display = 'block';
        document.querySelector('.outlooksSearchResults').style.display = 'none';
      }
    }
  });

  // Manage programs 'See all' button
  const seeAllButton = document.querySelectorAll('.programsSeeAllLink');
  if (seeAllButton.length > 0) {
    seeAllButton.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();

        const input = document.querySelector('[name="program-sector"]');
        input.tomselect.addItem(this.dataset.sector);
        const anchor = document.querySelector('.listing-filters');
        let y = 0;

        if (anchor) {
          const offset = 200;
          y = anchor.getBoundingClientRect().top + window.scrollY;
          y = y - offset;
        }

        window.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
      });
    });
  }

  // Manage formations 'See all' button
  const seeAllFormationsButton = document.querySelectorAll(
    '.formationsSeeAllLink'
  );
  if (seeAllFormationsButton.length > 0) {
    seeAllFormationsButton.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();

        const input = document.querySelector('[name="formation-domain"]');
        input.tomselect.addItem(this.dataset.domain);
        const anchor = document.querySelector('.listing-filters');
        let y = 0;

        if (anchor) {
          const offset = 200;
          y = anchor.getBoundingClientRect().top + window.scrollY;
          y = y - offset;
        }

        window.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
      });
    });
  }

  // Manage workshops 'See all' button
  const seeAllWorkshopsButtons = document.querySelectorAll(
    '.workshopsSeeAllLink'
  );
  if (seeAllWorkshopsButtons.length > 0) {
    seeAllWorkshopsButtons.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();

        const input = document.querySelector('[name="workshop-category"]');
        input.tomselect.addItem(this.dataset.category);
        const anchor = document.querySelector('.listing-filters');
        let y = 0;

        if (anchor) {
          const offset = 200;
          y = anchor.getBoundingClientRect().top + window.scrollY;
          y = y - offset;
        }

        window.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
      });
    });
  }

  // Manage outlooks 'See all' button
  const seeAllOutlooksButtons = document.querySelectorAll(
    '.outlooksSeeAllLink'
  );
  if (seeAllOutlooksButtons.length > 0) {
    seeAllOutlooksButtons.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();

        const input = document.querySelector('[name="outlook-sector"]');
        input.tomselect.addItem(this.dataset.sector);
        const anchor = document.querySelector('.listing-filters');
        let y = 0;

        if (anchor) {
          const offset = 200;
          y = anchor.getBoundingClientRect().top + window.scrollY;
          y = y - offset;
        }

        window.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
      });
    });
  }
});

document.addEventListener('findstrInit', function () {
  const findstr = window.findstr || {};
  findstr.hooks.addFilter(
    'setFacetsDistribution',
    'ithqSetFacetsDistribution',
    function (facets) {
      // Create an object which will serve as the order template
      const newOrder = {
        'formation-category': {
          Formation: null,
          'Formation de groupe': null,
          'Examen et certification': null,
          Manuel: null,
          'Training courses': null,
          'Group training courses': null,
          'Exams and certifications': null,
          Textbooks: null,
        },
        'program-education': {
          Professionnel: null,
          Technique: null,
          Universitaire: null,
          'Formation continue': null,
          Vocational: null,
          Technical: null,
          University: null,
          'Continuing education': null,
        },
        'company-category': {
          Tourisme: null,
          Hôtellerie: null,
          Restauration: null,
          Autre: null,
        },
        'formation-domain': {
          Tourisme: null,
          Restauration: null,
          'Expérience client': null,
          Gestion: null,
          Autre: null,
          Tourism: null,
          'Restaurant services': null,
          'Customer experience': null,
          Management: null,
          Other: null,
        },
        'formation-language': {
          Français: null,
          Anglais: null,
          Espagnol: null,
          English: null,
          French: null,
          Spanish: null,
        },
        'program-sector': {
          Tourisme: true,
          Tourism: true,
          Hôtellerie: true,
          'Hotel management': true,
          Restauration: true,
          'Restaurant services': true,
        },
        'program-schedule': {
          'Temps plein': true,
          'Full-time': true,
          'Temps partiel': true,
          'Part-time': true,
        },
        program_degree: {
          dep: true,
          asp: true,
          dec: true,
          aec: true,
          baa: true,
          bag: true,
          certificat: true,
          aeu: true,
          ithq: true,
          wset: true,
        },
        'program-language': {
          Français: true,
          Anglais: true,
          French: true,
          English: true,
        },
      };

      // loop through the new order object to reassign the facets new order.
      Object.keys(newOrder).forEach((key) => {
        //iterate object keys
        Object.keys(newOrder[key]).forEach((facetKey) => {
          if (!facets[key].hasOwnProperty(facetKey)) {
            delete newOrder[key][facetKey];
          }
        });

        facets[key] = Object.assign(newOrder[key], facets[key]);
      });

      return facets;
    }
  );

  /**
   * Custom order for dropdowns
   * (used for workshops)
   */
  findstr.hooks.addFilter(
    'setFacetsDistribution',
    'ithqSetFacetsDistribution',
    function (facets) {
      const moveToTheLast = {
        workshop_cities: 'En ligne',
        formation_cities: 'En ligne',
        'workshop-language': 'Anglais',
      };

      Object.keys(moveToTheLast).forEach((key) => {
        if (facets.hasOwnProperty(key)) {
          const value = facets[key][moveToTheLast[key]];
          delete facets[key][moveToTheLast[key]];
          facets[key][moveToTheLast[key]] = value;
        }
      });

      return facets;
    }
  );
});
