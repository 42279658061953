import { _x } from '@wordpress/i18n';

let fieldsAreInitialized = false;

function enableReadOnly() {
  const fields = document.querySelectorAll(
    '.gf_readonly input, .gf_readonly textarea'
  );
  if (0 < fields.length) {
    fields.forEach((field) => {
      field.setAttribute('readonly', '');
    });
  }
}

function changeFileUpload(formID) {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach((fileUpload) => {
      fileUpload.querySelector('.gform_drop_instructions').textContent = _x(
        'Drag and drop file to upload',
        'drag and drop instruction',
        'vtx'
      );
    });
  }

  let singleFileUploads;
  if (formID) {
    singleFileUploads = document.querySelectorAll(
      `form[data-formid="${formID}"] .ginput_container_fileupload`
    );
  } else {
    singleFileUploads = document.querySelectorAll(
      '.ginput_container_fileupload'
    );
  }

  if (0 < singleFileUploads.length) {
    singleFileUploads.forEach((fileUpload) => {
      const input = fileUpload.querySelector('input[type="file"]');
      if (!input) return;

      // Prevent duplicate fake-upload UI
      if (fileUpload.querySelector('.fake-upload')) return;

      const label = fileUpload
        .closest('.gfield')
        ?.querySelector('.gfield_label');
      const noFileLabel = _x('No file selected', 'file upload', 'vtx');

      // Create elements
      const wrapper = document.createElement('div');
      wrapper.classList.add('fake-upload');

      const uploadBtn = document.createElement('p');
      uploadBtn.classList.add('fake-upload__upload-btn');
      uploadBtn.innerHTML = `<span class="fake-upload__upload-btn__label">${_x(
        'Select a file',
        'file upload',
        'vtx'
      )}</span>`;

      const fileRow = document.createElement('div');
      fileRow.classList.add('fake-upload__file-row');

      const filePlaceholder = document.createElement('span');
      filePlaceholder.classList.add('fake-upload__file-placeholder');
      filePlaceholder.textContent = noFileLabel;

      const removeBtn = document.createElement('button');
      removeBtn.classList.add('fake-upload__remove-btn');
      removeBtn.setAttribute('type', 'button');
      removeBtn.innerHTML = `<span>${_x(
        'Clear file',
        'file upload',
        'vtx'
      )}</span>`;
      removeBtn.style.display = 'none';

      fileRow.append(filePlaceholder, removeBtn);
      wrapper.append(uploadBtn, fileRow);

      if (label) {
        label.after(wrapper);
      } else {
        fileUpload.appendChild(wrapper);
      }

      // Event Listeners
      uploadBtn.addEventListener('click', () => input.click());

      input.addEventListener('change', () => {
        filePlaceholder.textContent =
          input.files.length > 0 ? input.files[0].name : noFileLabel;
        removeBtn.style.display =
          input.files.length > 0 ? 'inline-flex' : 'none';
      });

      removeBtn.addEventListener('click', () => {
        input.value = '';
        filePlaceholder.textContent = noFileLabel;
        removeBtn.style.display = 'none';
      });

      input.addEventListener('focus', () => wrapper.classList.add('has-focus'));
      input.addEventListener('blur', () =>
        wrapper.classList.remove('has-focus')
      );
    });
  }
}

// Run when Gravity Forms is rendered
document.addEventListener('gform_post_render', function () {
  fieldsAreInitialized = true;
  changeFileUpload();
  enableReadOnly();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    enableReadOnly();
  }
});
