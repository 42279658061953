import domReady from '@wordpress/dom-ready';
import { _nx, sprintf } from '@wordpress/i18n';

domReady(() => {
  //since enqueue everywhere, avoid confusion and return
  const dropdownLocation = document.querySelector('#dropbtn');
  if (!dropdownLocation) {
    return;
  }

  //Manage all listing per address/city on workshop and formation single page
  const cityParam = 'findstr[filter][workshop_cities][value][]';
  const formationCityParam = 'findstr[filter][formation_cities][value][]';
  const urlParams = new URLSearchParams(window.location.search);
  let locationValues = urlParams.getAll(cityParam);
  if (0 === locationValues.length) {
    locationValues = urlParams.getAll(formationCityParam);
  }

  const dropdownLang = document.querySelector('#dropbtnLang');
  const activityFilter = document.querySelector('.activity-date-filter');
  const langFilter = document.querySelector('.activity-lang-filter');
  const dropdownElements = document.querySelectorAll(
    '.activity-location-dropown li'
  );
  const dropdownLangElements = document.querySelectorAll(
    '.activity-lang-dropown li'
  );
  let selectCountLocation = null;
  let selectLabelLocation = null;
  if (dropdownLocation) {
    selectLabelLocation = dropdownLocation.querySelector(
      '.dropbtn__placeholder'
    );
    selectCountLocation = dropdownLocation.querySelector(
      '.dropbtn__selected-count'
    );
  }
  let selectCountLang = null;
  let selectLabelLang = null;
  if (dropdownLang) {
    selectLabelLang = dropdownLang.querySelector('.dropbtn__placeholder');
    selectCountLang = dropdownLang.querySelector('.dropbtn__selected-count');
  }

  function checkedToggle(listElement) {
    const activityCheckbox = listElement.querySelector('input');
    activityCheckbox.checked = !activityCheckbox.checked;
    listElement.setAttribute(
      'aria-checked',
      activityCheckbox.checked.toString()
    );
  }

  //////
  // SET ALL LISTENERS
  //////

  //ON CLICK TO SHOW ITEMS
  dropdownLocation.addEventListener('click', () => {
    activityFilter.classList.toggle('show-filter');
  });
  dropdownLang.addEventListener('click', () => {
    langFilter.classList.toggle('show-filter');
  });

  // HIDE OPTIONS WHEN CLICK ELSEWHERE
  window.addEventListener('click', (e) => {
    e.stopPropagation();
    //if we are outside the dropdown and its filters
    if (
      dropdownLocation !== e.target &&
      selectCountLocation !== e.target &&
      selectLabelLocation !== e.target &&
      !Array.from(activityFilter.children).includes(e.target.parentElement) &&
      !e.target.classList.contains('box') &&
      !e.target.classList.contains('label') &&
      activityFilter.classList.contains('show-filter')
    ) {
      activityFilter.classList.remove('show-filter');
    }
    if (
      dropdownLang !== e.target &&
      selectCountLang !== e.target &&
      selectLabelLang !== e.target &&
      !Array.from(langFilter.children).includes(e.target.parentElement) &&
      !e.target.classList.contains('box') &&
      !e.target.classList.contains('label') &&
      langFilter.classList.contains('show-filter')
    ) {
      langFilter.classList.remove('show-filter');
    }
  });

  //ON CLICK TO FILTER ITEMS
  dropdownElements.forEach((element) => {
    element.addEventListener('click', () => {
      checkedToggle(element);
      showActivities();
    });
  });
  dropdownLangElements.forEach((element) => {
    element.addEventListener('click', () => {
      checkedToggle(element);
      showActivities();
    });
  });

  //////
  // MAIN FUNCTION CALL FOR SHOW ACTIVITIES
  //////
  function showActivities() {
    const currentLangs = [];
    const currentLocations = [];
    Array.from(dropdownLangElements).forEach((langListCheckbox) => {
      if (langListCheckbox.getAttribute('aria-checked') === 'true') {
        currentLangs.push(langListCheckbox.dataset.language);
      }
    });
    if (selectCountLang) {
      if (currentLangs.length) {
        selectCountLang.textContent = sprintf(
          /* translators: %s is number of options selected */
          _nx(
            ' (%s selected)',
            ' (%s selected)',
            currentLangs.length,
            'items selected count',
            'vtx'
          ),
          currentLangs.length
        );
      } else {
        selectCountLang.textContent = '';
      }
    }
    Array.from(dropdownElements).forEach((activityListCheckbox) => {
      if (activityListCheckbox.getAttribute('aria-checked') === 'true') {
        currentLocations.push(activityListCheckbox.dataset.location);
      }
    });
    if (selectCountLocation) {
      if (currentLocations.length > 0) {
        selectCountLocation.textContent = sprintf(
          /* translators: %s is number of options selected */
          _nx(
            ' (%s selected)',
            ' (%s selected)',
            currentLocations.length,
            'items selected count',
            'vtx'
          ),
          currentLocations.length
        );
      } else {
        selectCountLocation.textContent = '';
      }
    }
    const allActivities = document.querySelectorAll(
      '.activity-dates-block__date-item'
    );
    allActivities.forEach((activity) => {
      let isToDisplay = true;
      //location of this activity is note in current location
      if (
        currentLocations.length &&
        !currentLocations.includes(activity.dataset.location)
      ) {
        isToDisplay = false;
      }
      if (isToDisplay && currentLangs.length) {
        let langFound = false;
        const activityLangs = activity.dataset.language.split(',');
        activityLangs.forEach((activityLang) => {
          if (!langFound && currentLangs.includes(activityLang)) {
            langFound = true;
          }
        });
        isToDisplay = langFound;
      }
      activity.classList.toggle('show-item', isToDisplay);
    });
  }

  if (locationValues.length !== 0) {
    locationValues.forEach((location) => {
      const locationListItem = document.querySelector(
        `li[data-location="${location}"]`
      );
      if (locationListItem) {
        checkedToggle(locationListItem);
      }
    });
  }
  showActivities();
});
